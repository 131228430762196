import {
  StyledSection,
  StyledContent,
  StyledText,
  StyledSocialMedia,
  StyledFacebookIcon,
  StyledLinkedinIcon,
  StyledTwitterIcon,
  StyledInstagramIcon,
  StyledYoutubeIcon,
} from "./Copywrite.styles"

export const CopyWrite = () => (
  <StyledSection>
    <StyledContent>
      <div>
        <StyledText>&copy; 2024. All rights reserved.</StyledText>
      </div>
      <StyledSocialMedia>
        {process.env.REACT_APP_SOCIAL_MEDIA_FACEBOOK && (
          <a
            href={process.env.REACT_APP_SOCIAL_MEDIA_FACEBOOK}
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <StyledFacebookIcon color="var(--footer-copywrite-social-icons-color)" />
            </figure>
          </a>
        )}
        {process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM && (
          <a
            href={process.env.REACT_APP_SOCIAL_MEDIA_INSTAGRAM}
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <StyledInstagramIcon
                color="#fff"
                fill="var(--footer-copywrite-social-icons-color)"
              />
            </figure>
          </a>
        )}
        {process.env.REACT_APP_SOCIAL_MEDIA_LINKEDIN && (
          <a
            href={process.env.REACT_APP_SOCIAL_MEDIA_LINKEDIN}
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <StyledLinkedinIcon color="var(--footer-copywrite-social-icons-color)" />
            </figure>
          </a>
        )}
        {process.env.REACT_APP_SOCIAL_MEDIA_TWITTER && (
          <a
            href={process.env.REACT_APP_SOCIAL_MEDIA_TWITTER}
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <StyledTwitterIcon color="var(--footer-copywrite-social-icons-color)" />
            </figure>
          </a>
        )}
        {process.env.REACT_APP_SOCIAL_MEDIA_YOUTUBE && (
          <a
            href={process.env.REACT_APP_SOCIAL_MEDIA_YOUTUBE}
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <StyledYoutubeIcon
                color="var(--footer-copywrite-social-icons-color)"
                fillPlayIcon="var(--footer-copywright-background-color)"
              />
            </figure>
          </a>
        )}
      </StyledSocialMedia>
    </StyledContent>
  </StyledSection>
)
