import { createContext, useContext, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useLocalStorage } from "hooks"

type AuthStore = {
  token: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  login: (token: string) => Promise<void>
  logout: () => void
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const AuthContext = createContext<AuthStore>({
  token: null,
  login: async () => {},
  logout: () => {},
})

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorage("token_v0", null)
  const navigate = useNavigate()

  // call this function when you want to authenticate the user
  const login = async (token: string) => {
    setToken(token)
    navigate("/")
  }

  // call this function to sign out logged in user
  const logout = () => {
    setToken(null)
    navigate("/auth/login", { replace: true })
  }

  const value = useMemo(
    () => ({
      token,
      login,
      logout,
    }),
    [token],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
