import { useEffect } from "react"
import { useDocumentListStore } from "../stores"
import { useTreeviewStore } from "stores/treeview"
import { clog } from "utils"
import { useMediabagStore } from "../stores/mediabag"
import { SWRConfig } from "swr"
import { useAuth } from "hooks"

function getHeaders() {
  const headers = new Headers()
  headers.append("customer", process.env.REACT_APP_CUSTOMER_UID || "")
  // Check if token exist
  const authData = sessionStorage.getItem("auth")
  if (authData) {
    const token = JSON.parse(authData)
    headers.append("Authorization", `Bearer ${token}`)
  }
  return headers
}

const fetcher = (url: string) =>
  fetch(url, { headers: getHeaders() }).then((r) => r.json())

type Props = {
  children: React.ReactNode
}

export const BaseResources = ({ children }: Props) => {
  const auth = useAuth()
  const { initLoad } = useDocumentListStore()
  const mediabagStore = useMediabagStore()
  const { get } = useTreeviewStore()

  // Check if user is authenticated
  useEffect(() => {
    const controller = new AbortController()

    const getTreeview = async () => {
      //setIsloading(true)
      try {
        await get(controller)
      } catch (err) {
        clog("failed to get treeview.", err)
        // TODO: check what error is returned
        auth.logout()
      }
    }

    const loadBaseResources = () => {
      // Load default config from cookie
      initLoad()
      // Get treeview
      getTreeview()
      // Load mediabag
      mediabagStore.load()
    }

    loadBaseResources()
    return () => {
      // cancel the request
      controller.abort()
    }
  }, [])

  return <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
}
