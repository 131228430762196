import { AxiosResponse } from "axios"
import { useEffect, useState } from "react"
import { useMediabagStore } from "stores"
import { cErr, damAPI } from "utils"
import { File } from "types"
import { MediabagTemplate } from "./MediabagTemplate"
import { LoadingContainer } from "../components/LoadingContainer"
import { SiteTitle } from "jsx/components/SiteTitle"

export const Mediabag = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [docs, setDocs] = useState<File[]>([])
  const mediabagStore = useMediabagStore()

  useEffect(() => {
    const controller = new AbortController()

    const loadBag = async () => {
      if (mediabagStore.items.length === 0) {
        setIsLoading(false)
        setDocs([])
        return
      }

      // Create array of all product variants to fetch
      const urls: string[] = []
      mediabagStore.items.forEach((obj) => {
        urls.push(`/file/${obj.fileId}`)
      })

      // Create a new unique list
      const uniqueUrls = [...new Set(urls)]

      try {
        const resp: AxiosResponse<File>[] = await Promise.all(
          uniqueUrls.map((url) =>
            damAPI.get(url, { signal: controller.signal }),
          ),
        )
        if (Array.isArray(resp)) {
          // Create a list of all product variants
          const list: File[] = []
          resp.forEach((res) => {
            if (res.data) {
              list.push(res.data)
            }
          })
          setDocs(list)
        }
      } catch (err) {
        // TODO: show error page
        cErr("~ unable to get product variant from the API.", err)
      } finally {
        setIsLoading(false)
      }
    }

    loadBag()
    return () => {
      // cancel the request
      controller.abort()
    }
  }, [mediabagStore.items])

  if (isLoading) {
    return <LoadingContainer />
  }

  return (
    <>
      <SiteTitle title="Mediabag" />
      <MediabagTemplate items={docs} isShared={false} />
    </>
  )
}
