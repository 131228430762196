import { useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useTreeviewStore } from "stores"
import type { AuthResp } from "types/Auth"
import { Input, Header, FormButton, FormResponse, FormLink } from "./atoms"
import { AUTH_FAILED } from "../../constants/common"
import {
  StyledContent,
  StyledBox,
  StyledForm,
  StyledInputBox,
} from "./Auth.styles"
import { useAuth } from "hooks"

export const Auth = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  const nameRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const treeviewStore = useTreeviewStore()
  const [isLoading, setIsLoading] = useState(false)
  const [errorText, setErrorText] = useState("")

  const onClickSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Sanity check
    if (!nameRef?.current?.value || !passwordRef?.current?.value) {
      setErrorText(AUTH_FAILED)
      return
    }
    setIsLoading(true)

    if (errorText) {
      // Clear old error message
      setErrorText("")
    }

    const requestOptions = {
      method: "POST",
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "Content-Type": "application/json",
        application: process.env.REACT_APP_APP_UID || "",
        customer: process.env.REACT_APP_CUSTOMER_UID || "",
      },
      body: JSON.stringify({
        email: nameRef.current.value.trim(),
        password: passwordRef.current.value,
      }),
    }

    const url = `${process.env.REACT_APP_API_ACCOUNT}/v0/auth/login`
    const resp = await fetch(url, requestOptions)
    if (!resp.ok) {
      setErrorText(AUTH_FAILED)
      setIsLoading(false)
      return
    }

    const jsonData: AuthResp = await resp.json()
    if (jsonData) {
      // Set token to context
      await auth.login(jsonData.token)
      // Reload folders
      const controller = new AbortController()
      await treeviewStore.get(controller)
    }

    // Redirect user to home page
    navigate("/")
  }

  return (
    <StyledContent>
      <StyledBox>
        <StyledForm onSubmit={onClickSignIn}>
          <Header title="Sign in to your account" />
          <StyledInputBox>
            <Input
              type="text"
              label="Email"
              name="name"
              ref={nameRef}
              autofocus
            />
          </StyledInputBox>
          <StyledInputBox>
            <Input
              type="password"
              label="Password"
              name="password"
              ref={passwordRef}
              autofocus={false}
            />
          </StyledInputBox>
          <FormButton isLoading={isLoading} text="Sign In" />
        </StyledForm>
        <FormResponse text={errorText} hasError />
        <FormLink url="/auth/forgot-password" text="Forgot your password?" />
      </StyledBox>
    </StyledContent>
  )
}
