import { downloadFile, damAPI, cErr } from "utils"
import { GenerateSignedUrlResp } from "types/SignedUrl"
import { AxiosResponse } from "axios"
import { File } from "types"

export const downloadFileHandler = async (
  file: File,
  filename: string,
  selectedRes: string,
) => {
  let genSignedUrl = `/file/${file.id}/download/signed-url`

  // Check selected image resolution.
  if (selectedRes && selectedRes.toLowerCase() !== "original") {
    genSignedUrl += `?resolution_id=${selectedRes}`
  }

  // Generate a signed download url
  let downloadUrl: string | undefined = undefined
  const resp: AxiosResponse<GenerateSignedUrlResp> =
    await damAPI.get(genSignedUrl)
  downloadUrl = resp.data?.url || ""

  if (!downloadUrl) {
    cErr("invalid download url.", downloadUrl)
    throw new Error("invalid download url")
  }

  // Start download the image
  try {
    await downloadFile(downloadUrl, filename)
  } catch (err) {
    throw new Error(err)
  }
}
