import { Link, useNavigate } from "react-router-dom"
import { useSWRConfig } from "swr"
import { useMediabagStore } from "stores"
import {
  StyledContent,
  StyledBagRow,
  StyledProfileIcon,
  StyledMediabagIcon,
  StyledDivider,
  StyledSignoutText,
} from "./Navigation.styles"
import { useAuth } from "hooks/auth_provider"

export const Navigation = () => {
  const navigate = useNavigate()
  const auth = useAuth()
  const { cache } = useSWRConfig()
  const mediabagStore = useMediabagStore()

  const onClickSignOut = async () => {
    auth.logout()

    // Remove mediabag from store
    mediabagStore.clear()

    // Clear SWR cache
    // eslint-disable-next-line
    const c = cache as any
    c.clear()

    // TODO: Clear store
    // await treeviewStore.get(controller)

    // Redirect to startpage
    navigate("/")
  }

  return (
    <StyledContent>
      {auth.token === null ? (
        <Link to="/auth/login" style={{ textDecoration: "none" }}>
          <StyledBagRow>
            <StyledProfileIcon />
          </StyledBagRow>
        </Link>
      ) : (
        <StyledBagRow onClick={onClickSignOut}>
          <StyledSignoutText>Sign out</StyledSignoutText>
          <StyledProfileIcon />
        </StyledBagRow>
      )}
      {auth.token !== null && (
        <>
          <StyledDivider />
          <Link to="/mediabag" style={{ textDecoration: "none" }}>
            <StyledBagRow>
              <StyledMediabagIcon />
              <p>{mediabagStore.amount}</p>
            </StyledBagRow>
          </Link>
        </>
      )}
    </StyledContent>
  )
}
