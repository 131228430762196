import { BrowserRouter } from "react-router-dom"
import { Token } from "./styles/Token"
import { Footer } from "./components/footer"
import { GlobalStyles } from "./styles/GlobalStyles"
import { SiteTitle } from "./components/SiteTitle"
import { Navbar } from "./components/navbar"
import { Routes } from "./Routes"
import { StyledAppSection, StyledAppInner } from "./app.styles"
import { BaseResources } from "./BaseResources"
import { AuthProvider } from "hooks"

export const App = () => {
  return (
    <BrowserRouter>
      <Token />
      <SiteTitle />
      <GlobalStyles />
      <AuthProvider>
        <BaseResources>
          <Navbar />
          <StyledAppSection>
            <StyledAppInner>
              <Routes />
            </StyledAppInner>
            <Footer />
          </StyledAppSection>
        </BaseResources>
      </AuthProvider>
    </BrowserRouter>
  )
}
