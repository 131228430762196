import { StyledContent, StyledInput } from "./Search.styles"

type Props = {
  readonly value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

export const SearchContent = ({ value, onChange, onKeyDown }: Props) => (
  <StyledContent>
    <StyledInput
      type="text"
      value={value}
      onChange={onChange}
      autoComplete="off"
      placeholder="Search asset"
      onKeyDown={onKeyDown}
      maxLength={50}
    />
  </StyledContent>
)
