import { useAuth } from "hooks"
import { Navigate, useLocation } from "react-router-dom"

export const ProtectedRoute = ({ children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (auth.token === null) {
    // user is not authenticated
    return <Navigate to="/auth/login" replace state={{ from: location }} />
  }

  return children
}
