import { useRef, useState, useEffect } from "react"
import { AxiosResponse } from "axios"
import { useMediabagStore } from "stores"
import { IntroText } from "./IntroText"
import { ErrorMessage } from "./ErrorMessage"
import { useOnClickOutside } from "hooks"
import { cErr, damAPI } from "utils"
import {
  StyledOverlay,
  StyledInputBox,
  StyledCopyBtn,
  StyledCloseBtn,
  StyledHeader,
  StyledContent,
  StyledModalContent,
  StyledIconBox,
} from "./ShareUrlView.styles"

// Create a new bag
type MediabagSharePost = {
  id: string
  resolution_id: string | null
}
// Response body when creating a new bag
type MediabagShareResp = {
  id: string // Id of the new mediabag created
}

export const ShareUrlView = ({ close }) => {
  const mediabagStore = useMediabagStore()

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  const urlRef = useRef<HTMLInputElement>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMsg] = useState("")
  const [bagURL, setBagUrl] = useState("")
  const [isTextCopied, setTextCopied] = useState(false)

  useEffect(() => {
    const controller = new AbortController()

    const generateNewBag = async () => {
      const { items } = mediabagStore
      const payload: MediabagSharePost[] = []

      // Add bag items to list
      const itemsLength = items.length
      for (let i = 0; i < itemsLength; i++) {
        payload.push({
          id: items[i].fileId,
          resolution_id: items[i].resolutionId,
        })
      }

      // Call API and create a new bag
      try {
        const url = "/mediabag"
        const resp: AxiosResponse<MediabagShareResp> = await damAPI.post(
          url,
          payload,
          { signal: controller.signal },
        )
        if (resp?.data?.id) {
          setBagUrl(
            `https://${window.location.hostname}/mediabag/${resp.data.id}`,
          )
        }
      } catch (err) {
        // TODO: show error to user
        cErr("unable to create mediabag", err)
      }

      setIsLoading(false)
    }

    generateNewBag()
    return () => {
      // cancel the request
      controller.abort()
    }
  }, [mediabagStore])

  // Handle keyup events
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        close()
      }
    }

    window.addEventListener("keyup", handleKeyUp)
    return () => {
      window.addEventListener("keyup", handleKeyUp)
    }
  }, [close])

  const onClickCopy = async () => {
    if (!urlRef.current || !urlRef.current.value) {
      return
    }
    // Create new element
    const el = document.createElement("textarea")
    // Set value (string to be copied)
    el.value = urlRef.current.value
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute("readonly", "")
    el.setAttribute(
      "style",
      "white-space: pre; position: absolute; left: -9999px;",
    )
    document.body.appendChild(el)
    // Select text inside element
    el.select()
    // Copy text to clipboard
    document.execCommand("copy")
    // Remove temporary element
    document.body.removeChild(el)
    setTextCopied(true)
    // Wait 1.5 seconds
    await new Promise((resolve) => setTimeout(resolve, 1500))
    setTextCopied(false)
  }

  if (isLoading) {
    return null
  }

  if (errorMsg) {
    return (
      <StyledOverlay>
        <StyledModalContent ref={ref}>
          <ErrorMessage msg={errorMsg} />
        </StyledModalContent>
      </StyledOverlay>
    )
  }

  return (
    <StyledOverlay>
      <StyledModalContent ref={ref}>
        <div>
          <StyledHeader>
            <StyledIconBox onClick={close}>
              <StyledCloseBtn />
            </StyledIconBox>
          </StyledHeader>
          <StyledContent>
            <IntroText />
            <StyledInputBox type="text" value={bagURL} ref={urlRef} disabled />
            <StyledCopyBtn onClick={onClickCopy} disabled={isTextCopied}>
              {isTextCopied ? "Link copied" : "Copy link"}
            </StyledCopyBtn>
          </StyledContent>
        </div>
      </StyledModalContent>
    </StyledOverlay>
  )
}
