import type { ListPreviewImage } from "types"
import { useAuth } from "hooks"
import { StyledPicture } from "./ImageElement.styles"

type Props = {
  readonly preview: ListPreviewImage[]
}

export const ImageElement = ({ preview }: Props) => {
  const auth = useAuth()

  // Get image thumb
  const thumb = preview[3] || preview[2] || preview[1] || null
  if (thumb === null) {
    return <StyledPicture />
  }

  if (auth.token) {
    thumb["avif"] = thumb["avif"] + `?token=${auth.token}`
    thumb["jpeg"] = thumb["jpeg"] + `?token=${auth.token}`
    thumb["webp"] = thumb["webp"] + `?token=${auth.token}`
  }

  return (
    <StyledPicture>
      {thumb["avif"] && <source type="image/avif" srcSet={thumb["avif"]} />}
      {thumb["webp"] && <source type="image/webp" srcSet={thumb["webp"]} />}
      <img src={thumb["jpeg"]} alt="" />
    </StyledPicture>
  )
}
