import { ReactNode, useLayoutEffect } from "react"

type Props = {
  children: ReactNode
  loadMore: () => void
}

export const HandleScroll = ({ children, loadMore }: Props) => {
  useLayoutEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight
      const hasScrolledToBottom =
        Math.ceil(scrollTop + clientHeight) >= scrollHeight - 2000

      if (hasScrolledToBottom) {
        loadMore()
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return <>{children}</>
}
