import { SetState } from "zustand"
import { AxiosResponse } from "axios"
import { damAPI } from "utils/DamAPI"
import { Treeview } from "types/Treeview"
import { sortJSONArray } from "functions/sorting"
import { State } from "./State"
import { sortRecursiveLists } from "./addToStore"

// Get treeview from the API
export const get = async (
  set: SetState<State>,
  controller: AbortController,
) => {
  try {
    const url = "/treeview"
    const resp: AxiosResponse<Treeview> = await damAPI.get(url, {
      signal: controller.signal,
    })
    if (resp.data) {
      if (resp.data.nodes && resp.data.nodes.length > 0) {
        // Sort first level
        resp.data.nodes.sort(sortJSONArray("name", "asc"))
        // Sort levels
        sortRecursiveLists(resp.data.nodes)
      }
      set({ treeview: resp.data })
    }
  } catch (err) {
    // TODO:
    throw new Error("")
  }
}
