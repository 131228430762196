import { Route, Routes as R } from "react-router-dom"
import { Home } from "./home/Index"
import { Auth, ForgotPassword, Restore } from "./auth"
import { Search } from "./search"
import { Mediabag, MediabagRemote } from "./mediabag"
import { FolderList } from "./FolderList/FolderList"
import { ProtectedRoute } from "./protectedRoute"

export const Routes = () => (
  <R>
    <Route
      path=""
      element={
        <ProtectedRoute>
          <Home />
        </ProtectedRoute>
      }
    />
    <Route path="auth/login" element={<Auth />} />
    <Route path="auth/forgot-password" element={<ForgotPassword />} />
    <Route path="auth/restore/:id" element={<Restore />} />
    <Route
      path="mediabag"
      element={
        <ProtectedRoute>
          <Mediabag />
        </ProtectedRoute>
      }
    />
    <Route
      path="mediabag/:mediabagId"
      element={
        <ProtectedRoute>
          <MediabagRemote />
        </ProtectedRoute>
      }
    />
    <Route
      path="search/:elem"
      element={
        <ProtectedRoute>
          <Search />
        </ProtectedRoute>
      }
    />
    <Route
      path=":name/*"
      element={
        <ProtectedRoute>
          <FolderList />
        </ProtectedRoute>
      }
    />
  </R>
)
