import { useEffect, useState } from "react"
import { AxiosResponse } from "axios"
import { useParams, useNavigate } from "react-router-dom"
import { MediabagTemplate } from "./MediabagTemplate"
import { File } from "types"
import { damAPI, cErr } from "utils"
import { useMediabagStore } from "stores"
import { LoadingContainer } from "../components/LoadingContainer"

type MediabagSharedItem = {
  id: string
  resolution_id: string | null
}

type MediabagSharedResponse = {
  id: string
  files: MediabagSharedItem[]
}

// Show remote mediabag
export const MediabagRemote = () => {
  const { mediabagId } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [docs, setDocs] = useState<File[]>([])
  const mediabagStore = useMediabagStore()

  // Clear mediabag on unmount
  useEffect(() => {
    return () => {
      mediabagStore.clear()
    }
  }, [])

  useEffect(() => {
    const controller = new AbortController()

    const loadRemote = async () => {
      let data: MediabagSharedResponse | undefined = undefined

      // Get mediabag details
      try {
        const url = `/mediabag/${mediabagId}`
        const resp: AxiosResponse<MediabagSharedResponse> = await damAPI.get(
          url,
          {
            signal: controller.signal,
          },
        )
        if (resp?.data) {
          data = resp.data
        }
      } catch (err) {
        cErr("failed to load remote bag", err)
        // TODO: show error
        // Redirect back to home
        navigate("/")
        return
      }

      // Get all items from API
      const urls: string[] = []
      data?.files.forEach((file) => {
        urls.push(`/file/${file.id}`)
      })

      try {
        const resp: AxiosResponse<File>[] = await Promise.all(
          urls.map((url) => damAPI.get(url, { signal: controller.signal })),
        )
        if (Array.isArray(resp)) {
          // Create a list of all product variants
          const list: File[] = []
          resp.forEach((res) => {
            if (res.data) {
              list.push(res.data)
            }
          })
          // Add mediabag items to bag
          data?.files.forEach((f) => {
            mediabagStore.add(f.id, f.resolution_id)
          })
          setDocs(list)
        }
        setIsLoading(false)
      } catch (err) {
        cErr("~ unable to get product variant from the API.", err)
        navigate("/")
        return
      }
    }

    loadRemote()
    return () => {
      // cancel the request
      controller.abort()
    }
  }, [mediabagId, navigate])

  if (isLoading) {
    return <LoadingContainer />
  }

  return <MediabagTemplate isShared items={docs} />
}
