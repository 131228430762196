import { create } from "zustand"
import { createTrackedSelector } from "react-tracked"
import { Treeview } from "types/Treeview"
import { State } from "./State"
import { addToStore } from "./addToStore"
import { get } from "./get"

export const useTreeviewStore = createTrackedSelector(
  create<State>((set) => ({
    treeview: {} as Treeview,
    // Request new data from API
    get: (controller) => get(set, controller),
    // Add fetched data to store
    addToStore: (data) => addToStore(set, data),
  })),
)
